import styled from 'styled-components';

const BORDER_STYLE = '1px solid #ccc';

export const Table = styled.div`
  border: ${BORDER_STYLE};
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;
  margin-bottom: 1.5rem;
  border-bottom: none;
`;

export const RowItem = styled.div`
  border-right: ${BORDER_STYLE};
  border-bottom: ${BORDER_STYLE};
  padding: 0.8rem 2rem;

  &:nth-child(odd) {
    font-weight: bold;
  }

  &:nth-child(even) {
    border-right: none;
  }

  &:nth-child(3) {
    padding: 3rem 2rem;
    align-self: center;
  }
`;
