import React from 'react';
import PropTypes from 'prop-types';
import { RowItem, Table } from './tableInfo.style';
import mdToHtml from 'common/src/utils/mdToHtml';

const TableInfo = ({ rows = [], ...props }) => {
  const formatedRows = rows.map(({ fieldName, fieldValue }) => {
    const fieldValueToHtml = fieldValue.map(text => mdToHtml(text));

    return {
      fieldName,
      fieldValue: fieldValueToHtml,
    };
  });

  return (
    <Table>
      {formatedRows.map(row => (
        <>
          <RowItem>{row.fieldName}</RowItem>
          <RowItem>
            {row.fieldValue.map(text => (
              <>
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
                <br />
              </>
            ))}
          </RowItem>
        </>
      ))}
    </Table>
  );
};

TableInfo.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default TableInfo;
